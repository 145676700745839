
import mixins from 'vue-typed-mixins';
import { SelectOption } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import { mapGetters } from 'vuex';

export default mixins(NotificationMixin).extend({
  name: 'SupplierManagementPermissionSectionForm',

  props: {
    source: {
      type: Object as () => {permissions: SelectOption[]; permissionsSelected: string},
      required: true,
    },

    isRequired: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
    }),

    permissionHalfLength(): number {
      return Math.round(this.source.permissions.length / 2);
    },
  },

  methods: {
    emitCheck() {
      this.$emit('onCheck');
    },
  },

});
